import Cookies from 'js-cookie';
// import jwt from 'jsonwebtoken';
import jwt_decode from 'jwt-decode';

type AuthStateType = {
    authenticated: boolean;
    token: string;
    jwt: any;
};

const defaultAuthState: AuthStateType = {
    authenticated: false,
    token: '',
    jwt: {},
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
const state = ref<AuthStateType>(defaultAuthState);

const mutations = {
    setToken({ token, jwt }: { token: string; jwt: any }) {
        if (token && token !== '' && process.browser) {
            // const Cookies = await require('js-cookie')
            // console.log('setting token', token, jwt)
            state.value.jwt = jwt;
            state.value.token = token;
            Cookies.set('woomsession', token, { expires: 60, path: '/', secure: process.env.NODE_ENV === 'production' });
        }
    },
    // check token is called on each transition, read from cookie
    checkToken() {
        if (process.client) {
            // const Cookies = await require('js-cookie')
            const v = Cookies.get('woomsession');
            if (v) {
                if (state.value.token !== v) {
                    // const jwt = await require('jsonwebtoken')
                    state.value.token = v;
                    state.value.jwt = jwt_decode(v);
                }
                return v;
            }
        }
        return '';
    },
};

const actions = {};

const getters = {
    authenticated() {
        return state.value.authenticated;
    },
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useAuthStore = defineStore('woom-store-auth', () => {
    return { state, getters, mutations, actions };
});
