import { ApolloError } from '@apollo/client';
import { useAuthStore } from './auth';
import { CheckoutMutation_ManageCheckout_Checkout, PageByUrlQuery_PageByUrl_Page_Product_Promo } from '~/common/types/nestedGraphqlTypes';
import { CheckoutActionType, FeedVariant, useCheckoutMutation } from '~/graphql/generated';
import { setApolloClient } from '~/common/helpers';
import { GTM } from '~/plugins/gtm';

export const CheckoutAction = {
    CREATE: 'CREATE',
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    REMOVE: 'REMOVE',
    REPLACE: 'REPLACE',
    FETCH: 'FETCH',
};

// const namespaced = true;

/** global store actions */
// export const ACTION_CART_FETCH = 'cart/fetch';
// export const ACTION_CART_UPDATE = 'cart/update';
// export const ACTION_BUNDLE_UPDATE = 'cart/updateBundle';
// export const ACTION_CART_ADD = 'cart/add';
// export const ACTION_CART_ADD_BUNDLE = 'cart/addBundle';
// export const ACTION_CHECK_PROMO = 'cart/canAddPromo';

type CartStateType = {
    checkout: CheckoutMutation_ManageCheckout_Checkout;
    loaded: boolean;
};

const defaultAppState: CartStateType = {
    checkout: null,
    loaded: false,
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
const state = ref<CartStateType>(defaultAppState);

const mutations = {
    setCheckout(checkout: CheckoutMutation_ManageCheckout_Checkout) {
        state.value.checkout = checkout;
    },
    setCheckoutLoaded(loaded: boolean) {
        state.value.loaded = loaded;
    },
    clearCheckout() {
        state.value.checkout = null;
    },
};

const getters = {
    hasLeadProduct: (products: string[]) => {
        if (products && products.length === 0) {
            return true;
        }
        if (state.value.checkout && state.value.checkout.lineItems) {
            for (const li of state.value.checkout.lineItems) {
                if (li.variant && li.variant.productRef) {
                    const idx = li.variant.productRef.indexOf('shop_product:');
                    if (idx > 0) {
                        const sub = li.variant.productRef.substring(idx + 13);
                        if (products.includes(sub)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    },
    //         //
    //         // isLimitReached: (state) => (productId, limit) => {
    //         //   // console.log('is limit reached', productId, limit);
    //         //   let total = 0;
    //         //   if (state.checkout && state.checkout.lineItems) {
    //         //     for(const li of state.checkout.lineItems) {
    //         //       if (li.variant && li.variant.productRef) {
    //         //         if (li.variant.productRef === productId) {
    //         //           total += li.quantity
    //         //         }
    //         //         // console.log('compare with product ref', li.variant.productRef, li.quantity)
    //         //       }
    //         //     }
    //         //   }
    //         //   if (total >= limit) {
    //         //     return true;
    //         //   }
    //         //   return false;
    //         // },
};

const actions = {
    async fetch(input: { checkoutId: any; locale: string; $gtm: GTM; cartEvent: string }) {
        const authStore = useAuthStore();
        authStore.mutations.checkToken();
        // we only create
        // this.commit('auth/checkToken');
        // now check if we have a cartId in the rootState
        // console.log('fetch cart')
        let checkoutId = input.checkoutId;
        if (!checkoutId && authStore.state.jwt && authStore.state.jwt.cartId) {
            checkoutId = authStore.state.jwt.cartId;
            // console.log('checkout id', checkoutId)
        }
        if (checkoutId) {
            // console.log('we have a checkout id')
            // with an existing checkout id - fetch the checkout
            // if we already have a checkout in our state, skip the refetch
            setApolloClient();
            // const client = this.app.apolloProvider.defaultClient;
            // if (client) {

            const mutation = useCheckoutMutation();

            try {
                const response = await mutation.mutate({
                    locale: input.locale,
                    checkoutId,
                    action: CheckoutActionType.Fetch,
                });

                // console.log('got response', response)
                const checkout = response?.data?.manageCheckout?.checkout || null;

                mutations.setCheckout(checkout);
                mutations.setCheckoutLoaded(true);

                // persist in datalayer
                if (input.$gtm) {
                    input.$gtm.updateCart(checkout);
                }

                //if we need this, we need to make sure it's passed in as a parameter
                // const nuxtApp = useNuxtApp();

                // if (nuxtApp.$gtm) {
                //     nuxtApp.$gtm.updateCart(checkout);
                // }

                // nuxtApp.$gtm.updateCart(checkout, input.cartEvent);

                const userErrors = response?.data?.manageCheckout?.userErrors || [];
                if (userErrors.length > 0) {
                    console.log('error', userErrors);
                }
                // TODO - discuss with Bruno what we do with the user errors - inline or snacbar (sequence or bulk)
            } catch (err) {
                const apolloErr = err as ApolloError;
                console.log('got an error', apolloErr.message, apolloErr.graphQLErrors, apolloErr.networkError, apolloErr.extraInfo);
            }
        } else {
            if (input.$gtm) {
                // set the default cart datalayer
                input.$gtm.updateCart(null);
            }
        }
    },
    //         // update({ commit, state }, input) {
    //         //     return new Promise((resolve, reject) => {
    //         //         const client = this.app.apolloProvider.defaultClient;
    //         //         // we check for client - need to fix for storybook
    //         //         const lineItems = [];
    //         //         // console.log('cart - update', input)
    //         //         if (client) {
    //         //             // do we have a promo item?
    //         //             if (state.checkout && state.checkout.lineItems) {
    //         //                 const i = state.checkout.lineItems.find((l) => l.id === input.itemId);
    //         //                 if (i && i.customAttributes) {
    //         //                     const promo_ref = i.customAttributes.find((p) => p.key === '_promo_src');
    //         //                     if (promo_ref) {
    //         //                         // ok it's a promo item
    //         //                         // check the current quantity
    //         //                         if (i.quantity > input.quantity) {
    //         //                             // lets find the corresponding item
    //         //                             const other = state.checkout.lineItems.find((p) => p.variant.productRef === promo_ref.value);
    //         //                             if (other) {
    //         //                                 if (input.quantity <= 0) {
    //         //                                     lineItems.push({ id: other.id });
    //         //                                 } else {
    //         //                                     lineItems.push({ id: other.id, quantity: input.quantity });
    //         //                                 }
    //         //                             }
    //         //                         }
    //         //                     }
    //         //                 }
    //         //             }
    //         //             if (input.quantity <= 0) {
    //         //                 lineItems.push({ id: input.itemId });
    //         //             } else {
    //         //                 lineItems.push({ id: input.itemId, quantity: input.quantity });
    //         //             }
    //         //             let add = input.quantity > 0;
    //         //             const comp = state.checkout.lineItems.find((k) => k.id === input.itemId);
    //         //             if (comp) {
    //         //                 add = input.quantity > comp.quantity;
    //         //             }
    //         //             // is the current quantity lesser
    //         //             const vars =
    //         //                 input.quantity <= 0
    //         //                     ? {
    //         //                           locale: input.locale,
    //         //                           checkoutId: input.checkoutId,
    //         //                           action: CheckoutAction.REMOVE,
    //         //                           lineItems: lineItems
    //         //                       }
    //         //                     : {
    //         //                           locale: input.locale,
    //         //                           checkoutId: input.checkoutId,
    //         //                           action: CheckoutAction.UPDATE,
    //         //                           lineItems: lineItems
    //         //                       };
    //         //             client
    //         //                 .mutate({
    //         //                     mutation: MUTATION_CHECKOUT,
    //         //                     variables: vars
    //         //                 })
    //         //                 .then((response) => {
    //         //                     const checkout = get(response, 'data.manageCheckout.checkout', null);
    //         //                     // console.log('update - check for line items', state.checkout.lineItems)
    //         //                     if (checkout) {
    //         //                         commit('setCheckout', checkout);
    //         //                         commit('setCheckoutLoaded', true);
    //         //                     }
    //         //                     if (input.$gtm) {
    //         //                         input.$gtm.updateCart(state.checkout);
    //         //                     }
    //         //                     const changes = checkout.lineItems.filter((p) => p.id === input.itemId);
    //         //                     if (vars.action === CheckoutAction.REMOVE) {
    //         //                         // remove for sure
    //         //                         if (this.$gtm) {
    //         //                             this.$gtm.removeCartItems(changes);
    //         //                         }
    //         //                     } else {
    //         //                         // add if we have v
    //         //                         if (this.$gtm) {
    //         //                             if (add) {
    //         //                                 this.$gtm.addCartItems(changes);
    //         //                             } else {
    //         //                                 this.$gtm.removeCartItems(changes);
    //         //                             }
    //         //                         }
    //         //                     }
    //         //                     const userErrors = get(response, 'data.manageCheckout.userErrors', []);
    //         //                     if (userErrors.length > 0) {
    //         //                         console.log('error', userErrors);
    //         //                         reject(userErrors);
    //         //                     } else {
    //         //                         resolve(true);
    //         //                     }
    //         //                     // TODO - discuss with Bruno what we do with the user errors - inline or snacbar (sequence or bulk)
    //         //                 })
    //         //                 .catch((err) => {
    //         //                     console.log('got an error', err.message, err.graphQLErrors, err.networkError, err.extraInfo);
    //         //                     reject(err);
    //         //                 });
    //         //         } else {
    //         //             reject(new Error('no client found'));
    //         //         }
    //         //     });
    //         // },
    //         // updateBundle({ commit, state }, input) {
    //         //     // console.log('update bundle', input)
    //         //     const vars =
    //         //         input.quantity <= 0
    //         //             ? {
    //         //                   locale: input.locale,
    //         //                   checkoutId: input.checkoutId,
    //         //                   action: CheckoutAction.REMOVE,
    //         //                   lineItems: input.lineItems
    //         //               }
    //         //             : {
    //         //                   locale: input.locale,
    //         //                   checkoutId: input.checkoutId,
    //         //                   action: CheckoutAction.UPDATE,
    //         //                   lineItems: input.lineItems
    //         //               };
    //         //     // pick the first item to check if the quantity changes up or down
    //         //     let add = input.quantity > 0;
    //         //     if (input.lineItems && input.lineItems[0]) {
    //         //         // compare this line item to the current quantity
    //         //         const comp = state.checkout.lineItems.find((l) => l.id === input.lineItems[0].id);
    //         //         if (comp) {
    //         //             add = comp.quantity < input.lineItems[0].quantity;
    //         //         }
    //         //     }
    //         //     const client = this.app.apolloProvider.defaultClient;
    //         //     if (client) {
    //         //         client
    //         //             .mutate({
    //         //                 mutation: MUTATION_CHECKOUT,
    //         //                 variables: vars
    //         //             })
    //         //             .then((response) => {
    //         //                 const checkout = get(response, 'data.manageCheckout.checkout', null);
    //         //                 if (checkout) {
    //         //                     commit('setCheckout', checkout);
    //         //                     commit('setCheckoutLoaded', true);
    //         //                 }
    //         //                 if (input.$gtm) {
    //         //                     input.$gtm.updateCart(state.checkout);
    //         //                 }
    //         //                 const ids = input.lineItems.map((li) => li.id);
    //         //                 const changes = [];
    //         //                 for (const li of checkout.lineItems) {
    //         //                     if (ids.indexOf(li.id) >= 0) {
    //         //                         changes.push(li);
    //         //                     }
    //         //                 }
    //         //                 // console.log('changes', changes);
    //         //                 if (vars.action === CheckoutAction.REMOVE) {
    //         //                     // remove for sure
    //         //                     if (this.$gtm) {
    //         //                         this.$gtm.removeCartItems(changes);
    //         //                     }
    //         //                 } else {
    //         //                     // input quantity
    //         //                     if (add) {
    //         //                         if (this.$gtm) {
    //         //                             this.$gtm.addCartItems(changes);
    //         //                         }
    //         //                     } else {
    //         //                         if (this.$gtm) {
    //         //                             this.$gtm.removeCartItems(changes);
    //         //                         }
    //         //                     }
    //         //                 }
    //         //                 const userErrors = get(response, 'data.manageCheckout.userErrors', []);
    //         //                 if (userErrors.length > 0) {
    //         //                     console.log('error', userErrors);
    //         //                 }
    //         //                 // TODO - discuss with Bruno what we do with the user errors - inline or snacbar (sequence or bulk)
    //         //             })
    //         //             .catch((err) => {
    //         //                 console.log('got an error', err.message, err.graphQLErrors, err.networkError, err.extraInfo);
    //         //             });
    //         //     }
    //         // },
    //         // add({ commit, state }, { variantId, locale, name, promo, preorder, preordername, $gtm, properties }) {
    //         //     const props = {
    //         //         ...(properties ? properties : {})
    //         //     };
    //         //     if (name) {
    //         //         props.Name = name;
    //         //     }
    //         //     if (preorder) {
    //         //         props[preordername ? preordername : 'Pre-Order'] = preorder;
    //         //     }
    //         //     if (promo) {
    //         //         if (promo.ref) {
    //         //             props['_promo_src'] = promo.ref;
    //         //         } else if (promo._promo_ref) {
    //         //             props['_promo_ref'] = promo._promo_ref;
    //         //             props['Promotion'] = promo.label;
    //         //             props['_is_promo_item'] = 'true';
    //         //         }
    //         //     }
    //         //     // console.log('cart - add', props)
    //         //     return new Promise((resolve, reject) => {
    //         //         const client = this.app.apolloProvider.defaultClient;
    //         //         if (client) {
    //         //             const hasCheckout = state.checkout ? true : false;
    //         //             let currentItems = [];
    //         //             if (hasCheckout) {
    //         //                 currentItems = state.checkout.lineItems.map((l) => l.id);
    //         //             }
    //         //             client
    //         //                 .mutate({
    //         //                     mutation: MUTATION_CHECKOUT,
    //         //                     variables: {
    //         //                         locale,
    //         //                         checkoutId: state.checkout ? state.checkout.id : undefined,
    //         //                         action: state.checkout ? CheckoutAction.ADD : CheckoutAction.CREATE,
    //         //                         lineItems: [Object.keys(props).length > 0 ? { variantId, quantity: 1, props: props } : { variantId, quantity: 1 }]
    //         //                     }
    //         //                 })
    //         //                 .then((response) => {
    //         //                     // console.log('got response', response)
    //         //                     const checkout = get(response, 'data.manageCheckout.checkout', null);
    //         //                     if (checkout) {
    //         //                         commit('setCheckout', checkout);
    //         //                         commit('setCheckoutLoaded', true);
    //         //                     }
    //         //                     if (this.$gtm && checkout && checkout.lineItems) {
    //         //                         // console.log('add - state.checkout vs checkout', state.checkout, checkout)
    //         //                         this.$gtm.updateCart(checkout);
    //         //                         if (hasCheckout) {
    //         //                             // filter items out
    //         //                             const changes = [];
    //         //                             for (const li of checkout.lineItems) {
    //         //                                 if (currentItems.indexOf(li.id) < 0) {
    //         //                                     changes.push(li);
    //         //                                 }
    //         //                             }
    //         //                             // was it an add, that contains a variantId that already exists in the lineItems
    //         //                             if (changes.length === 0) {
    //         //                                 // find the variant id
    //         //                                 console.log('check variant id', variantId, checkout.lineItems);
    //         //                                 const comp = checkout.lineItems.find((l) => l.variant && l.variant.id === variantId);
    //         //                                 if (comp) {
    //         //                                     changes.push(comp);
    //         //                                 }
    //         //                             }
    //         //                             this.$gtm.addCartItems(changes);
    //         //                         } else {
    //         //                             // take the checkout items
    //         //                             this.$gtm.addCartItems(checkout.lineItems);
    //         //                         }
    //         //                     }
    //         //                     const userErrors = get(response, 'data.manageCheckout.userErrors', []);
    //         //                     // TODO - discuss with Bruno what we do with the user errors - inline or snacbar (sequence or bulk)
    //         //                     if (userErrors.length > 0) {
    //         //                         console.log('error', userErrors);
    //         //                     }
    //         //                     resolve('done');
    //         //                 })
    //         //                 .catch((err) => {
    //         //                     console.log('got an error', err.message, err.graphQLErrors, err.networkError, err.extraInfo);
    //         //                     reject(err);
    //         //                 });
    //         //         } else {
    //         //             reject(new Error('no apollo client'));
    //         //         }
    //         //     });
    //         // },
    //         // addBundle({ commit, state }, { bundle, price, variants, locale, image, lead, $gtm }) {
    //         //     // set the default props for all variants
    //         //     const payload = [];
    //         //     const props = {
    //         //         Bundle: bundle.label,
    //         //         _bundle: bundle.code,
    //         //         _bundle_price: `${price}`
    //         //     };
    //         //     if (lead && lead !== '') {
    //         //         props['_bundle_lead'] = lead;
    //         //     }
    //         //     if (image && image !== '') {
    //         //         props['_bundle_image'] = image;
    //         //     }
    //         //     // console.log('default props', props, bundle, variants, locale)
    //         //     // create payload
    //         //     for (const v of variants) {
    //         //         const f = bundle.products.components.find((p) => p.shopify && p.shopify.handle === v.handle);
    //         //         // console.log('variant v', v, f, v.handle)
    //         //         payload.push({
    //         //             variantId: v.id,
    //         //             quantity: 1,
    //         //             props: {
    //         //                 ...props,
    //         //                 _bundle_code: v.orderCode
    //         //             }
    //         //         });
    //         //     }
    //         //     // console.log('payload', payload)
    //         //     return new Promise((resolve, reject) => {
    //         //         const client = this.app.apolloProvider.defaultClient;
    //         //         if (client) {
    //         //             client
    //         //                 .mutate({
    //         //                     mutation: MUTATION_CHECKOUT,
    //         //                     variables: {
    //         //                         locale,
    //         //                         checkoutId: state.checkout ? state.checkout.id : undefined,
    //         //                         action: state.checkout ? CheckoutAction.ADD : CheckoutAction.CREATE,
    //         //                         lineItems: payload
    //         //                     }
    //         //                 })
    //         //                 .then((response) => {
    //         //                     // console.log('got response', response)
    //         //                     const checkout = get(response, 'data.manageCheckout.checkout', null);
    //         //                     if (checkout) {
    //         //                         commit('setCheckout', checkout);
    //         //                         commit('setCheckoutLoaded', true);
    //         //                         // console.log('add bundle', checkout)
    //         //                     }
    //         //                     if ($gtm && checkout && checkout.lineItems) {
    //         //                         // console.log('add - state.checkout vs checkout', state.checkout, checkout)
    //         //                         $gtm.updateCart(checkout);
    //         //                         // now find all line items which belong to this bundle
    //         //                         const bundleItems = checkout.lineItems.filter((l) => {
    //         //                             console.log('filter l', l);
    //         //                             if (l.customAttributes) {
    //         //                                 if (l.customAttributes.find((a) => a.key === '_bundle' && a.value === bundle.code)) {
    //         //                                     return true;
    //         //                                 }
    //         //                             } else {
    //         //                                 return false;
    //         //                             }
    //         //                         });
    //         //                         // console.log('find bundle items', bundleItems)
    //         //                         if (this.$gtm) {
    //         //                             this.$gtm.addCartItems(bundleItems);
    //         //                         }
    //         //                     }
    //         //                     const userErrors = get(response, 'data.manageCheckout.userErrors', []);
    //         //                     // TODO - discuss with Bruno what we do with the user errors - inline or snacbar (sequence or bulk)
    //         //                     if (userErrors.length > 0) {
    //         //                         console.log('error', userErrors);
    //         //                     }
    //         //                     resolve('done');
    //         //                 })
    //         //                 .catch((err) => {
    //         //                     console.log('got an error', err.message, err.graphQLErrors, err.networkError, err.extraInfo);
    //         //                     reject(err);
    //         //                 });
    //         //         } else {
    //         //             reject(new Error('no apollo client'));
    //         //         }
    //         //     });
    //         // },
    // promo: PageQuery_PageByUrl_Page_Product_Promo

    // doesn't do anything, so typing params doesn't matter?
    canAddPromo({ variant, promo }: { variant: any; promo: string }) {
        // look in the cart line items
        return true;
    },
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useCartStore = defineStore('woom-store-cart', () => {
    return { state, getters, mutations, actions };
});
